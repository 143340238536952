/* AlertBox.css */

.alert-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 9998; /* Behind the popup */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .alert-content {
    background-color: white!important;
    padding: 30px!important;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 9999; /* Above the overlay */
    text-align: center;
  }
  
  .alert-content h2 {
    margin-bottom: 20px;
  }
  
  .alert-content .qr-code {
    margin: 15px auto;
  }
  
  .alert-content button {
    margin-top: 20px;
    padding: 5px 10px;
    font-size: 1rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .alert-content button:hover {
    background-color: #1f65a3;
  }
  