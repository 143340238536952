@import url('https://fonts.googleapis.com/css2?family=Quattrocento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.App {
    /* text-align: center; */
    height: 100%;
    font-family: 'Lato', serif;
  }
  .App .navbar {
    height: 100px;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)!important;
  }
  .profileAccess button:focus,
  .profileAccess button:active{
    -webkit-appearance:none!important;
  }
  .App header{
    height: 100%;
  }
  .App header .content{
    height: 100%;
  }
  .App .logo {
    /* animation: App-logo-spin infinite 20s linear;
    height: 40vmin; */
    margin-left: 30px;
    height: 50px!important;
    width: 160px!important;
    pointer-events: none;
  }
  
  /* .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  } */
  
  /* .App-link {
    color: #61dafb;
  } */
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .App-note {
    color: #61dafb;
    background-color: blanchedalmond;
  }
  
  .pop-upbox {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Scanner-box {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #3795bd;
  }
  
  h4 {
    color: #000;
    /* text-align: center; */
    margin: 0px auto 16px;
  }
  
  .borderbtnhome {
    position: relative;
    border: 2px solid #2ea3f2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.8em !important;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
    color: #2ea3f2 !important;
  }
  
  .none {
    display: none
  }
  
  svg {
    padding: 21px;
    background: #fff;
  }
  
  .data-container {
    margin: 25px 10px 0px 10px;
    padding: 25px;
    border: 1px solid white;
    color: white;
    width: 80%;
  }
  
  .detail {
    display: flex;
  }
  
  p.info {
    padding-left: 10px;
  }
  
  /**saurabh css**/
  /* .navbar {
    padding-bottom: 15px;
    padding-top: 15px;
    background: #065ec9;
  } */
  
  .profileAccess {
    display: flex;
  }
  
  .profileIcon {
    cursor: pointer;
  
    margin-left: 30px;
    width: 36px;
  }
  
  .dropDown {
    position: relative;
  }
  
  .dropdown-profile {
    list-style: none;
    color: white;
    background-color: #007bffc4;
    position: absolute;
    padding-left: 0px;
    width: 230px;
    top: 58px;
    left: -53px;
    border: 1px solid #0175f3;
  }
  
  .dropdown-list1 {
    padding: 10px;
    border-bottom: 1px solid #ffff;
  }
  
  li:hover {
    /* background-color: #065ec9; */
    cursor: pointer;
  }
  
  .dropdown-list {
    padding: 5px;
  }
  
  a.nav-link {
    color: white !important;
  }
  
  .login_btn {
    background: white;
  }
  
  .cartIcon {
    margin-left: 30px;
    width: 36px;
  }
  
  .cartText {
    font-style: italic;
    font-weight: 600;
    font-family: sans-serif;
    font-size: 16px;
    color: white;
  }
  
  header .hero-section {
    /* background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100vh - 75px) !important;
    height: 100vh; */
    /* background-image: linear-gradient(180deg,#ffffff 4%,rgba(255,255,255,0) 100%),url(back1.png)!important; */
    /* background-image: url(ecomm.jpg); */
  }

  .modal_container{
    opacity: 2000;
  }
  
  .modal-content {
    margin-top: 70px;
    /* background: #065ec9; */
    background: #fff;
    
  }
  
  .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
    text-align: center!important;
    align-items: center;
  }
  
  .p-btn {
    background: white;
    color: #065ec9;
    width: 110px;
  }
  
  .p-btn:hover {
    background: white;
    color: #065ec9;
  }
  
  .modal-footer {
    text-align: center;
    border-top: none;
    display: flex;
    justify-content: center!important;
  }
  
  img.spinner {
    width: 135px !important;
  }
  
  .welcomeLabel {
    color: white;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  
  .btnView {
    margin-right: 10px;
  }
  
  .logo {
    /* max-width: 50px; */
  }
  
  /* .logo-text {
    font-style: italic;
    font-family: sans-serif;
    font-size: 30px;
    color: white;
  } */
  
  .clickable {
    cursor: pointer;
  }
  
 
  
  /* New CSS - Vaibhav (Neobank) */
  
  
  .navbar-nav .nav-item{
      
    font-weight: 400;
    font-size: 0.5rem;
  }
  .navbar-nav .nav-link{
    color: #000!important;
  }
  /* .navbar-nav .nav-link:hover{
    border-bottom: 2px solid #000;
  } */

  .nav-buttons{
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
    padding: 17px ;
    color: #fff;
    font-weight: bold;
    /* background-color: rgba(0, 136, 169, 1); */
    border: none;
    cursor: pointer;
    transition: all .3s ease 0s;
    background: #3498db;
    border-radius: 10px;
  }

  .nav-buttonss{
    display: inline-block;
    margin-right: 10px;
    padding: 17px ;
    color: #fff;
    /* background-color: rgba(0, 136, 169, 1); */
    border: none;
    cursor: pointer;
    transition: all .3s ease 0s;
    background: #3498db;
  }
  
.modal_container{
  z-index: 2000!important;
}


  .custom-popup-container {
    background-color: #f2f2f2;
    border-radius: 10px;
}

.custom-popup-title {
    color: #333;
    font-size: 24px!important;
    padding: 20px 20px 0 20px!important;
    margin-top: 20px!important;
}

.custom-popup-content {
    color: #666;
    font-size: 18px;
}

.custom-popup-confirm-button {
    background-color: #fff!important;
    color: #29b8ff!important;
    border: 3px solid #29b8ff!important;
    border-radius: 10px!important;
    font-size: 18px!important;
    margin-bottom: 20px!important;
}

.custom-popup-deny-button{
    background-color: #fff!important;
    color: #29b8ff!important;
    border: 3px solid #29b8ff!important;
    border-radius: 10px!important;
    font-size: 18px!important;
    margin-bottom: 20px!important;
}

.custom-popup-confirm-button:hover {
    background-color: #29b8ff!important;
    color: #fff!important;
    border: 3px solid #29b8ff!important;
    border-radius: 10px!important;
    font-size: 18px!important;
    margin-bottom: 20px!important;
}

.custom-popup-deny-button:hover{
    background-color: #29b8ff!important;
    color: #fff!important;
    border: 3px solid #29b8ff!important;
    border-radius: 10px!important;
    font-size: 18px!important;
    margin-bottom: 20px!important;
}


.close-button-containerr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.close-button-containerr h4{
  margin: 0 auto;
  padding-left: 50px;
}
.close-buttonn{
  background: #29b8ff;
  border-radius: 0 0 0 20px;
}

.modal-body{
  margin-bottom: 30px;
}

.dropdown button.nav-buttons::after {
  content: none; /* Remove the default arrow */
}
