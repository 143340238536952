.admin-container {
    width: 100%;
    height: auto;
    background-color: #f2f2f2;
  }
  
  .sidebar {
    width:  18rem;
    background-color: #fff;
    padding-top: 20px;
    height: auto!important;
    min-height: 100vh!important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)!important;
  }

  
  .user-item {
    margin-top: 20px;
    margin-left: 7px;
    cursor: pointer!important;
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .user-item.selected {
    background-color: #3498db;
    width: 100%;
    color: #fff;
  }
  
  .pdf-viewer {
    align-items: center;
    margin-top: 30px;
    padding: 20px; 
  }
  
  .pdf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin: 20px 30px 50px 30px;
  }
  
  .action-buttons {
    display: flex;
    margin: 20px 0;
  }
  
  .issue-button, .vc-container button, .issue-all-btn button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 50px;
    cursor: pointer;
    border-radius: 10px;
  }

  .issue-all-btn button{
    margin: 30px;
  }
  .issue-button:hover {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .pdf-container {
    margin-bottom: 20px;
  }
  .no-user, .loading {
    padding-left: 50px;
    padding-top: 50px;
    font-size: 1.2rem;
    color: #777;
  }


  .pdf-header h3{
    padding-top: 10px;
    font-size: 2rem;
    font-weight: 600;
  }
  

.fileName{
    font-weight: 600;
    font-size: 2rem;
    margin-top: 90px;
    padding-left: 30px;
  }


  .docView{
    margin: 60px 0 30px 30px;
      }
    
      
      .dgtProof{
        margin-top: 65px;
        margin-left: 45px;
      }
    
      .changeViewbtn{
        margin: 60px 30px 20px 150px;
      }
      .verifyBtn{
        margin: 60px 30px 20px 30px;
      }

  @media (max-width: 768px) {
    .admin-container {
      flex-direction: column;
    }
  
    .sidebar {
      flex: 0 0 auto;
      width: 100%;
    }
  
    .content {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  

  .user-table {
    padding: 15px;
    background-color: #fff!important;
    width: 100%;
    border: none!important;
  }
  .user-tablee {
    padding: 15px;
    background-color: #fff!important;
    width: 90%;
    border-collapse: collapse;
    margin-left: 30px;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  }
  .clickable-name{
    cursor: pointer;
  }
  .user-table th,
  .user-table td {
    padding: 8px;
  }
  
  .user-table th {
    background-color: #F9F9F9;
    color: #7B7B80;
    font-size: 0.8125rem;
font-style: normal;
font-weight: 500;
  }
  
  .user-table tbody tr:nth-child(even) {
    background-color: #F6F8FD;
    color: var(--grey-text-900, #18181B);
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
  }
  .user-table tbody tr {
    color: var(--grey-text-900, #18181B);
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
  }
  .review-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .review-btn:hover {
    background-color: #45a049;
  }


  .miniNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    height: 70px;
    padding: 10px 30px;
  }
  .companyy{
    font-size: 1rem;
    font-weight: 600;
  }
  /* .bellIcon{
    background-color: #e7e7e7;
    border-radius: 50%;
  } */
  .stuProf{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .sidebarbtn{
    display: flex;
    width: 100%!important;
    align-items: center;
    background: none!important;
    color: #7B7B80!important;
    font-size: 0.85rem!important;
    text-align: left;
    margin-left: 7px;
  }
 
  .selected {
    background-color: #3498db!important;
    color: #fff!important;
    border-radius: 10px;
    width: 100%;
  }
  .selected .bellIcon {
    color: #fff;
  }
  .photo{
    height: 40px;
    width: 40px;
    background-color:  #e7e7e7;
    border-radius: 50%;
    padding: 5px;
  }
  .stuDentDiv{
    justify-content: left;
    text-align: left!important;
  }
  .stuUserNameHead{
    font-size: 0.7rem;
    font-weight: 600;
    color: #7B7B80;
    margin-left: 15px;
  }
  .stuUserName{
    font-size: 0.9rem;
    font-weight: 600;
    color: #000;
    margin-left: 15px;
  }
  .smallHr{
    justify-content: center;
    margin: 25px 0 20px 7px!important;
    width: 100%;
  }
  .bellIcon{
    color: #7B7B80;
    background: none!important;
    padding: 5px!important;
    margin-right: 10px;
  }
  .redText{
    color: red!important;
  }
  .redText .bellIcon{
    color: red!important;
  }
  .listTag{
    margin: 20px 0 20px 30px;
    font-size: 1.25rem;
  }
  .backBtnDiv{
    display: flex;
    align-items: center;
  }
  .backBtn{
    border: none;
    background: none;
    color: #7B7B80;
  }
  .backIcon{
    background: none;
    padding: 0!important;
    margin-right: 7px;
  }
  .eyeIcon{
    margin-left: 8px;
    background: none;
    padding: 0!important;
    color: #3498db;
    cursor: pointer;
    text-align: center;
  }
  .deleteIcon{
    margin-left: 50px;
    background: none;
    padding: 0!important;
    color: #cc0000;
    cursor: pointer;
    text-align: center;
  }
  .document-popup{
    background-color: #fff;
    padding: 25px;
    margin: 40px 20px;
  }
  .close-button{
    margin-left: auto;
  background: none;
  padding: 0 !important;
  cursor: pointer;
  }
  .close-button-container {
   display: flex;
   justify-content: right;
    padding-right: 10px; /* Adjust the padding as needed */
  }
  .studentName{
    margin: 30px 30px;
    font-size: 1.3rem;
  }
  .studentName span{
    color: #7B7B80;
    margin-right: 20px;
  }
  .adminBack{
display: flex;
justify-content: center;
align-items: center;
  }
  .adminBack img{
    margin: 40px 30px;
  }


   /* Style for the document popup */
.document-popup {
  position: fixed;
  padding: 30px;
  top: 0;
  left: 35%;
  width: auto;
  height: auto;/* Semi-transparent background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)!important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the popup is above other content */
}

/* Style for the popup content */
.document-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto; /* Add scroll bar if content exceeds max height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.document-popup h3{
  margin: 0!important;
  padding-bottom: 10px!important;
}
/* Style for the document viewer within the popup */
/* .document-popup-content .document-viewer {
  width: 60%!important;
  height: 60%!important;
} */