$c-primary: #0070f3;
$c-secondary: #757575;
$c-tertiary: #ffffff;
$c-success: #28a745;


@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 80px;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.logo img{
  margin-left: 50px;
  width: 210px;
  height: 60px;
  
}

.nav-links {
  display: flex;
}

.nav-link {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #000;
  }
}
.nav-item{
margin-right: 50px;
margin-top: 10px;
right: 0;
font-weight: 600;
color: #333;
}
.nav-item a{
  text-decoration: none;
  font-size: 1.2rem;
  right: 0;
font-weight: 600;
color: #333;
}

.nav-item a:hover{
color: #000;
}

/* Footer styles */
.footer {
  background-color: #e0e0e0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.footer-text {
  font-size: 14px;
  text-align: center;
}


.certificate-container {
  display: flex;
  flex-direction: column;
  align-items: center;

.certBack{
  
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
background-color: #e0e0e0;
}
  .certificate {
    margin-top: 50px;
    width: 800px;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 10px solid #013a63;
    background-color: $c-tertiary!important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

    .bodbox{
      width: 740px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 3px solid #013a63;
    background-color: $c-tertiary!important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;

      .favico img{
        align-items: center;
        width: 60px;
        height: 60px;
        margin-right: 40px;
        margin-left: 30px;
      }

      .title {
        font-size: 68px;
        font-weight: 700;
        color: #333;
        text-align: center;
        font-family: 'Tangerine', cursive;
      }
    }
    .title1{
      margin: 0;
      text-align: center;
    }
    .signature{
      font-size: 1.1rem;
      font-weight: 600;
    }
    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .name {
        font-size: 40px;
        font-weight: bold;
        font-family: 'Poiret One', cursive;
        color: #333;
        margin-bottom: 10px;
        text-decoration: 2px underline;
      }
    }
  }
    
  }

  .infosec{
    padding-top: 90px;
    margin-bottom: 50px;
  }
.leftsec{
  padding-right: 50px;

}
.coursename{
  font-weight: 600;
  color: #013a63;
  text-align: left;
  padding-left: 50px;
  padding-top: 10px;
}
.check img{
  height: 60px;
  width: 60px;
}
.stuname{
  margin-top: 50px;
  padding-left: 100px;
  font-size: 1.7rem;
  text-align: left;
}
.stuID{
  padding-left: 100px;
  text-align: left;
  font-size: 1.3rem;
}
.description{
  text-align: left;
  color: #757575;
  padding-top: 20px;
  padding-bottom: 20px;
}

.issueon, .expiry{
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
}
  .footer1 {
    margin-top: 30px;
    padding: 30px;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 2px solid #2d9cdb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

.verify{
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  text-align: left;
  padding: 10px 0;
}
.verify1{
  font-size: 1.2rem;
  font-weight: 400;
  color: #757575;
  text-align: left;
}
    .verify-btn {
      margin-top: 20px;
      width: 100%;
      height: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 400;
      color: $c-tertiary;
      background-color: #2d9cdb;
      border: none;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: darken(#2d9cdb, 10%);
      }
    }
  }

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .spinner {
      margin-left: 70px;
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
    }
    
    .spinner-text {
      margin-top: 10px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
    
    .spinner1 {
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  .success {
    color: #3498db;
    font-size: 24px;
  }
    .popup {
      width: 500px;
      background-color: $c-tertiary;
      border-radius: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      display: flex;
      flex-direction: column;

      .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .popup-title {
          font-size: 1.8rem;
          font-weight: bold;
          color: #013a63;
          text-align: center;
          margin-top: 20px;
          margin-left: 30px;
        }

        .popup-close {
          position: relative;
          top: 10px;
          right: 10px;
          font-size: 20px;
          color: #7f7f7f;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
        }
        
        .popup-body {
          padding: 20px;
        }

        
        
        // .verification-item {
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   margin-bottom: 10px;
        // }
        
        // .verification-label {
        //   font-weight: bold;
        //   font-size: 1.2rem;
        //   margin-right: 10px;
        //   color: #3498db!important;
        // }
        
        // .verification-result {
        //   display: flex;
        //   align-items: center;
        //   color: #fff;
        //   background-color: #5ebd73;
        //   padding: 5px 10px;
        //   border-radius: 5px;
        // }
        
        // .success {
        //   background-color: #5ebd73;
        // }
        
        .popup-footer {
          padding: 20px;
          background-color: #fff;
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid #ddd;
        }
        
        .share-btn {
          background-color: #2d9cdb;
          color: #fff;
          font-weight: bold;
          border-radius: 5px;
          padding: 10px 20px;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
        
        .share-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        
        .share-btn:active {
          transform: translateY(0);
          box-shadow: none;
        }
      }
    }
  }


  .verification-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .verification-item .verification-label {
    font-weight: bold;
    margin-right: 10px;
  }
  .verification-item .verification-result {
    display: flex;
    align-items: center;
  }
  .verification-item .spinner1,
  .verification-item .success {
    margin: 0 30px;
    font-size: 20px;
    width: 30px;
      height: 30px;
  }
  .verified{
    font-weight: bold;
    font-size: 1.4rem;
    margin-left: 15px;
  }
  .success1 img{
    height: 45px;
    width: 45px;
    margin-left: 20px;
  }