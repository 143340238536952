.containerrr {
    max-width: 800px;
    padding: 20px;
    text-align: center!important;
  }
  
  .containerrr h1{
    text-align: left!important;
    margin: 40px 0;
  }
  .message-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .message-table th,
  .message-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .message-table th {
    background-color: #f2f2f2;
  }
  
  .message-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  